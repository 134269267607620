import * as React from 'react';
import {CSSProperties} from 'react';

import {TableCardStyles} from './TableCards.styles';

interface TableCardsProps {
    columns: {
        customFormatter?: (originalObject: unknown, rowIndex?: number) => string | React.JSX.Element;
        fieldName?: string;
        hidden?: boolean;
        maskPII?: boolean;
        overrideTH?: (key: number, className: string) => React.JSX.Element;
        rightAlign?: boolean;
        style?: CSSProperties;
        title: string;
    }[];
    customFooter?: React.JSX.Element;
    customRowsAfter?: React.JSX.Element;
    data: unknown[];
    e2e?: string;
    disableLabels?: boolean;
    compactPadding?: boolean;
    printClasses?: boolean;
}

export const TableCards = ({
    columns,
    customFooter,
    customRowsAfter,
    data,
    e2e,
    disableLabels,
    compactPadding = false,
    printClasses = false,
}: TableCardsProps) => {
    return (
        <TableCardStyles>
            <table
                className={`tw-table-auto table-cards tw-w-full sm:tw-min-w-[375px] tw-mb-4${
                    printClasses ? ' print:tw-w-full print:tw-border-spacing-0 tw-mb-2.5' : ''
                }`}
                data-e2e={e2e}
            >
                <thead>
                    <tr className={`tw-bg-white tw-border-t tw-border-b`}>
                        {columns.map((column, index) =>
                            column.hidden ? (
                                ``
                            ) : column.overrideTH ? (
                                column.overrideTH(
                                    index,
                                    `tw-text-sm tw-whitespace-nowrap tw-font-normal tw-pl-3 lg:tw-min-h-[61px] tw-pr-6 tw-py-3.5${
                                        column.rightAlign ? ' lg:tw-text-right' : ''
                                    }${printClasses ? ' print:tw-border print:tw-border-black print:tw-p-2' : ''}
                                    }`,
                                )
                            ) : (
                                <td
                                    className={`tw-text-sm tw-whitespace-nowrap tw-font-normal tw-pl-3 lg:tw-min-h-[61px] tw-pr-6 tw-py-3.5${
                                        column.rightAlign ? ' lg:tw-text-right' : ''
                                    }${printClasses ? ' print:tw-border print:tw-border-black print:tw-p-2' : ''}`}
                                    key={index}
                                    style={column.style}
                                >
                                    {column.title}
                                </td>
                            ),
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((row, rowIndex) => (
                        <tr
                            className="tw-bg-white tw-border-solid tw-border tw-border-gray-200 lg:tw-border-x-0 lg:tw-border-b lg:tw-border-b-gray-200"
                            key={rowIndex}
                        >
                            {columns.map((column, colIndex) =>
                                column.hidden ? (
                                    ``
                                ) : (
                                    <td
                                        className={`tw-pl-3 tw-pr-6 lg:tw-my-3.5${column.rightAlign ? ' lg:tw-text-right' : ''}${
                                            column.maskPII ? ' maskPII' : ''
                                        }${printClasses ? ' print:tw-border print:tw-border-black print:tw-p-2' : ''}`}
                                        data-title={disableLabels ? undefined : column.title ? `${column.title}:` : undefined}
                                        key={colIndex}
                                        style={compactPadding ? {paddingTop: 2, paddingBottom: 2} : {paddingTop: 8, paddingBottom: 8}}
                                    >
                                        {column.customFormatter ? column.customFormatter(row, rowIndex) : row[column.fieldName]}
                                    </td>
                                ),
                            )}
                        </tr>
                    ))}
                    {customRowsAfter}
                </tbody>
                {!!customFooter && <tfoot className="tfoot">{customFooter}</tfoot>}
            </table>
        </TableCardStyles>
    );
};
