import {Account, AccountType} from '../../users/user.types';
import {AccountFinderAccountList} from './AccountFinderAccountList';
import {AccountFinderDistrict} from './AccountFinderDistrict';
import {AccountFinderDivision} from './AccountFinderDivision';
import {AccountFinderParent} from './AccountFinderParent';
import {Debounce} from '../../tools/debounce';

type AccountFinderViewProps = {
    account: Account;
};

const fields = [`account`, `attn`, `city`, `custAcct`, `name`, `st`];

const filterAllAccounts = Debounce((filter: string, accounts: Account[], cb: () => void) => {
    if (!filter) {
        makeVisible(accounts);
    } else {
        filterChildren(filter, accounts);
    }

    cb();
}, 700);

const filterVisible = () => (account: Account) => {
    return !account.hidden;
};

const filterChildren = (filter: string, accounts: Account[]) => {
    let hasVisible = false;

    for (const account of accounts) {
        const hasChildMatch = account.accounts && filterChildren(filter, account.accounts);

        account.hidden = !hasChildMatch && !matchFields(filter, account);
        if (!account.hidden) {
            hasVisible = true;
        }
    }

    return hasVisible;
};

const getAccountFinderView: any = (accountType: AccountType) => {
    switch (accountType) {
        case `Parent`:
            return AccountFinderParent;
        case `Division`:
            return AccountFinderDivision;
        case `District`:
            return AccountFinderDistrict;
        case `Account`:
            return AccountFinderAccountList;
    }
};

const makeVisible = (accounts: Account[]) => {
    for (const account of accounts) {
        account.hidden = false;

        if (account.accounts) {
            makeVisible(account.accounts);
        }
    }
};

const matchFields = (filter: string, account: Account) => {
    return !!fields.find((field) => account[field]?.toString().toLowerCase().includes(filter.toLowerCase()));
};

export {AccountFinderViewProps, filterAllAccounts, filterVisible, getAccountFinderView, matchFields};
